import { Table, Td } from '../components/table';
import { useReport } from './hooks/use-context';

export function InfosTable() {
  const { header } = useReport();

  if (!header) return null;

  const {
    event_created_at,
    event_finished_at,
    author_email,
    hide_score,
    score_obtained,
    total_score,
    local,
    percent_score,
    fantasy_name,
    company_name,
    address,
    document: companyDocument,
  } = header;

  return (
    <div
      style={{
        width: '100%',
        fontSize: '0.875rem',
        fontWeight: 700,
        breakInside: 'avoid',
      }}
    >
      <Table>
        <tbody>
          <tr>
            <Td>Iniciado em</Td>
            <Td>{!!event_created_at && event_created_at}</Td>
          </tr>

          <tr>
            <Td>Finalizado em</Td>
            <Td>{!!event_finished_at && event_finished_at}</Td>
          </tr>

          <tr>
            <Td>Autor</Td>
            <Td>{author_email}</Td>
          </tr>

          {!hide_score && score_obtained > 0 && (
            <tr>
              <Td>Pontuação do checklist</Td>
              <Td>
                {score_obtained} / {total_score} - {percent_score}%
              </Td>
            </tr>
          )}

          {!!local && (
            <tr>
              <Td>Local do evento:</Td>
              <Td>
                {` ${local.name}`}
                {local.address ? `, ${local.address}` : null}
                {local.city ? `, ${local.city}` : null}
                {local.state ? `, ${local.state}` : null}
              </Td>
            </tr>
          )}

          {!(!header.company_name || !header.fantasy_name) && (
            <tr>
              <Td>Dados da empresa:</Td>
              <Td>
                {fantasy_name ? ` ${fantasy_name}` : ''}
                {company_name ? ` - ${company_name}` : ''}
                {companyDocument ? `, ${companyDocument}` : ''}
                {address ? `, ${address}` : ''}
              </Td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}
