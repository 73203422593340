import { useWorkspace } from '@/hooks/workspace';
import { ReactComponent as WeCheckLogo } from '@assets/wc-logo-svg.svg';
import React, { useState } from 'react';
import styled from 'styled-components';

import { WorkspaceAwareLogoProps } from './interfaces';

export const Logo = styled(WeCheckLogo)`
  position: relative;
  width: 400px;
  height: 400px;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
`;

const WorkspaceAwareLogo: React.FC<WorkspaceAwareLogoProps> = ({ styles }) => {
  const { workspaceData } = useWorkspace();
  const [requestFailed, setRequestFailed] = useState(false);

  if (!workspaceData.logo_url || requestFailed) return <Logo style={styles} />;

  return (
    <div
      style={{
        width: 200,
        height: 200,
        alignItems: 'center',
        justifyContent: 'center',
        ...styles,
      }}
    >
      <img
        style={{
          maxWidth: '100%',
        }}
        src={workspaceData.logo_url}
        alt="logo"
        onError={() => setRequestFailed(true)}
      />
    </div>
  );
};

export default WorkspaceAwareLogo;
