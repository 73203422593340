/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash';

import { useQuestion } from './hooks/use-context';
import type {
  AnswerData,
  CollectionItemData,
  CollectionItemPropertiesData,
} from './types';

function MapContent() {
  const { answers } = useQuestion();

  const answer = answers?.[0];

  if (!answer) return <></>;

  return (
    <div
      style={{
        height: '100%',
        borderRadius: '0.5rem',
        border: '1px solid #D1D5DB',
      }}
    >
      <img
        style={{
          objectFit: 'contain',
          width: '100%',
        }}
        src={`${answer.content}`}
        alt="img"
      />
    </div>
  );
}

function CollectionItem(props: { collectionItem: CollectionItemData }) {
  const { collectionItem } = props;

  const renderProperty = (property: CollectionItemPropertiesData) => {
    return (
      <div
        key={property.name}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          fontSize: '0.75rem',
        }}
      >
        <span
          style={{
            marginRight: '0.5rem',
          }}
        >
          {property.name}
        </span>
        <span>{property.content}</span>
      </div>
    );
  };

  return (
    <div
      style={{
        fontSize: '0.75rem',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        border: '1px solid #F8FAFC',
        padding: '0.5rem',
        fontWeight: 600,
      }}
    >
      <p>Nome: {collectionItem?.name}</p>
      <p>Coleção: {collectionItem?.collection}</p>
      <p>Descrição: {collectionItem?.description}</p>
      <p>Código: {collectionItem?.code}</p>

      {collectionItem?.properties?.map((property) => renderProperty(property))}
    </div>
  );
}

function CollectionContent() {
  const { answers } = useQuestion();

  const renderItem = (answer: AnswerData) => {
    const { id, collection_item } = answer;
    return <CollectionItem key={id} collectionItem={collection_item} />;
  };

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '0.5rem',
      }}
    >
      {answers.map((answer) => renderItem(answer))}
    </div>
  );
}

function CheckboxContent() {
  const { answers } = useQuestion();

  const renderItem = (item: AnswerData) => {
    return (
      <li
        style={{
          width: '100%',
          overflow: 'hidden',
          borderRadius: '0.5rem',
          borderBottom: '1px solid #E5E7EB',
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '0.75rem',
            backgroundColor: item.red_flag ? '#c17c36' : 'transparent',
          }}
          key={item.id}
        >
          <input
            type="checkbox"
            defaultChecked
            style={{
              pointerEvents: 'none',
              height: '1.25rem',
              width: '1.25rem',
              borderRadius: '0.25rem',
              border: '1px solid #D1D5DB',
              outline: 'none',
              boxShadow: '0 0 0 2px transparent',
            }}
          />
          <label
            style={{
              marginLeft: '0.5rem',
              width: '100%',
              padding: '0.75rem 0',
              fontSize: '0.875rem',
              fontWeight: 500,
              color: item.red_flag ? 'white' : '#1F2937',
            }}
          >
            {item.option}
          </label>
        </div>
      </li>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
      }}
    >
      <ul
        style={{
          width: '12rem',
          borderRadius: '0.5rem',
          border: '1px solid #E5E7EB',
          backgroundColor: 'white',
          fontSize: '0.875rem',
          fontWeight: 500,
          color: '#1F2937',
        }}
      >
        {answers.map((answer) => renderItem(answer))}
      </ul>
    </div>
  );
}

function DefaultContent() {
  const { answers } = useQuestion();

  const renderAnswer = (answer: AnswerData) => {
    return <p>{answer?.content}</p>;
  };

  return <div>{answers.map((answer) => renderAnswer(answer))}</div>;
}

function GalleryContent() {
  const { answers } = useQuestion();

  const renderItem = (answer: AnswerData) => {
    return (
      <img
        key={answer.id}
        style={{
          breakInside: 'avoid',
          paddingTop: '1rem',
          marginBottom: '1rem',
          objectFit: 'contain',
          height: 'auto',
          maxHeight: '750px',
          borderRadius: '0.375rem',
        }}
        src={`${answer.content}`}
        alt="img"
      />
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {_.orderBy(answers, 'position', 'asc').map((answer) =>
        renderItem(answer),
      )}
    </div>
  );
}

export function AnswerContent() {
  const question = useQuestion();
  switch (question.type) {
    case 'gallery':
    case 'picture':
    case 'signature':
      return <GalleryContent />;
    case 'geolocation':
      return <MapContent />;
    case 'checkbox':
    case 'checkbox_combo':
    case 'radio':
    case 'radio_combo':
      return <CheckboxContent />;

    case 'collections':
      return <CollectionContent />;

    default:
      return <DefaultContent />;
  }
}
