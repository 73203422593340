/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash';

import { useQuestion } from './hooks/use-context';
import type {
  AnswerData,
  CollectionItemData,
  CollectionItemPropertiesData,
} from './types';

function MapContent() {
  const { answers } = useQuestion();

  const answer = answers?.[0];

  if (!answer) return <></>;

  return (
    <>
      <div
        style={{
          height: '100%',
          borderRadius: '0.5rem',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#cbd5e1',
        }}
      >
        <img
          style={{
            width: '100%',
            height: 'auto',
          }}
          src={`${answer.content}`}
          alt="img"
        />
      </div>
      <p
        style={{
          marginTop: '0.5rem',
          fontSize: '0.875rem',
          fontWeight: 600,
          color: '#1f2937',
        }}
      >
        {`Latitude: ${answer.geolocation.latitude}, Longitude: ${answer.geolocation.longitude}`}
      </p>
    </>
  );
}

function CollectionItem(props: { collectionItem: CollectionItemData }) {
  const { collectionItem } = props;

  const renderProperty = (property: CollectionItemPropertiesData) => {
    return (
      <div
        key={property.name}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'flex-start',
          fontSize: '0.75rem',
        }}
      >
        <span style={{ marginRight: '0.5rem' }}>{property.name}</span>
        <span>{property.content}</span>
      </div>
    );
  };

  return (
    <div
      style={{
        fontSize: '0.75rem',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#f8fafc',
        padding: '0.5rem',
        fontWeight: 600,
      }}
    >
      <p>Nome: {collectionItem?.name}</p>
      <p>Coleção: {collectionItem?.collection}</p>
      <p>Descrição: {collectionItem?.description}</p>
      <p>Código: {collectionItem?.code}</p>

      {collectionItem?.properties?.map((property) => renderProperty(property))}
    </div>
  );
}

function CollectionContent() {
  const { answers } = useQuestion();

  const renderItem = (answer: AnswerData) => {
    const { id, collection_item } = answer;
    return <CollectionItem key={id} collectionItem={collection_item} />;
  };

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '0.5rem',
      }}
    >
      {answers.map((answer) => renderItem(answer))}
    </div>
  );
}

function CheckboxContent() {
  const { answers } = useQuestion();

  const renderItem = (item: AnswerData) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '0.75rem',
          backgroundColor: item.red_flag ? '#c17c36' : 'transparent',
        }}
        key={item.id}
      >
        <input
          type="checkbox"
          defaultChecked
          style={{
            pointerEvents: 'none',
            height: '1.25rem',
            width: '1.25rem',
            borderRadius: '0.25rem',
            border: '1px solid #d1d5db',
            outline: '2px solid transparent',
          }}
        />
        <label
          style={{
            marginLeft: '0.5rem',
            width: '100%',
            paddingTop: '0.75rem',
            paddingBottom: '0.75rem',
            fontSize: '0.875rem',
            fontWeight: 500,
            color: item.red_flag ? '#ffffff' : '#111827',
          }}
        >
          {item.option}
        </label>
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ul
        style={{
          borderRadius: '0.5rem',
          backgroundColor: '#f7fafc',
          fontSize: '0.875rem',
          fontWeight: 500,
          color: '#111827',
        }}
      >
        {answers.map((answer, index) => (
          <li
            style={{
              width: '100%',
              overflow: 'hidden',
              borderRadius: '0.5rem',
              border: '1px solid #e5e7eb',
              borderColor: '#e5e7eb',
              backgroundColor: '#ffffff',
              marginBottom: index === answers.length - 1 ? '0' : '5px',
            }}
          >
            {renderItem(answer)}
          </li>
        ))}
      </ul>
    </div>
  );
}

function DefaultContent() {
  const { answers } = useQuestion();

  const answer = answers[0];

  return <p>{answer?.content}</p>;
}

function GalleryContent() {
  const { answers } = useQuestion();

  const renderItem = (answer: AnswerData) => {
    return (
      <img
        key={answer.id}
        style={{
          marginBottom: '1rem',
          height: 'auto',
          borderRadius: '0.375rem',
          objectFit: 'contain',
        }}
        src={`${answer.content}`}
        alt="img"
      />
    );
  };

  return (
    <div
      style={{
        columnCount: 3,
        columnGap: '2rem',
      }}
    >
      {_.orderBy(answers, 'position', 'asc').map((answer) =>
        renderItem(answer),
      )}
    </div>
  );
}

export function AnswerContent() {
  const question = useQuestion();
  switch (question.type) {
    case 'gallery':
    case 'picture':
    case 'signature':
      return <GalleryContent />;
    case 'geolocation':
      return <MapContent />;
    case 'checkbox':
    case 'checkbox_combo':
    case 'radio':
    case 'radio_combo':
      return <CheckboxContent />;
    case 'collections':
      return <CollectionContent />;
    default:
      return <DefaultContent />;
  }
}
