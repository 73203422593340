/*    Auth Interfaces    */

import { UserPermissionsType } from '@/modules/core/domain/models/user';
import { string } from 'yup';

export type AuthParams = {
  email: string;
  password: string;
  uuid: string;
  origin: 'mobile' | 'web';
};

export type SignInParams = {
  email: string;
  password: string;
  workspace: string;
};

export type AuthReturn = {
  auth_token: string;
  user_infos: UserModel;
};

export interface AuthStorageData {
  token: string;
  user: UserModel;
  workspace: string;
}

export type UserModel = {
  id: string;
  email: string;
  username: string;
  full_name?: string;
  password_digest: string;
  avatar_url?: string;
  document?: string;
  phone?: string;
  role: string;
  uuid_devices: string[];
  limit_connections: number;
  created_at: Date;
  confirmed_at?: Date;
  deleted_at?: Date;
  max_children?: number;
  updated_at: Date;
  user_type: UserPermissionsType;
  is_owner: boolean;
  is_super_admin?: boolean;
  web?: boolean;
  mobile?: boolean;
  first_login?: boolean;
  permit_create_task?: boolean;
  read_all?: boolean;
  dashboard_only?: boolean;
};

export enum AuthenticationErrors {
  deviceLimitExceeded = 'device limit exceeded',
  invalidCredentials = 'invalid credentials',
}

/*    Database Interfaces    */

export enum SyncState {
  START,
  SUCCESS,
  FAILED,
  IDLE,
}

/*  Database Events */

export enum DatabaseEvent {
  SYNC_STATE,
  SCHEDULE_CHANGES,
}

export interface ITableNames {
  [name: string]: DatabaseEvent;
}

export interface SyncListenerData {
  state: SyncState;
  lastPulledAt: number;
}

export type SyncResult = {
  lastPull: number;
  modelsChanged: number[];
};

export interface DatabaseObserverCollection {
  [eventName: string]: Array<(data: SyncListenerData) => void>;
}

/* NetInfo Interfaces */

export interface NetState {
  isInternetReachable: boolean;
  isConnected: boolean;
}

export interface NetStateCallback {
  (state: NetState): void;
}

/* CloudStorage Interfaces */

export interface UploadResp {
  old: string;
  storage: string;
}

export interface CloudStoragePathData {
  fullPath?: string;
  basePath?: string;
  fileName?: string;
}

export interface CloundStorageUploadData extends CloudStoragePathData {
  file: File & { path?: string };
  setProgress?: (progress: number) => void;
  setFileDownloadUrl?: (url: string) => void;
}

/* Api Services Interfaces */

export interface ApiObject {
  [key: string]: any;
}

export interface BaseApiTemplateParams {
  optionsUrl?: string;
  entityId?: string;
}

export type BaseApiCreateParams = ApiObject;
export interface BaseApiEditParams {
  id: string;
}

export interface BaseApiUpdateParams {
  ids: string[];
  updateAll: boolean;
  data: BaseApiCreateParams;
}
export interface BaseApiDeleteParams {
  ids: string[];
}

export interface BaseApiFetchOptionsParams {
  url: string;
}

export const ExportParentableTypes = {
  schedules: 'schedules',
  action_plans: 'action_plans',
  events: 'events',
  dash: 'dash',
};

export type ExportParentableTypeEnum = keyof typeof ExportParentableTypes;

export const ExportTypes = {
  xlsx: 'xlsx',
  pdf: 'pdf',
  csv: 'csv',
  zip: 'zip',
  pptx: 'pptx',
};

export type ExportTypeEnum = keyof typeof ExportTypes;

export const ImportTypes = {
  local: 'local',
  formulary: 'formulary',
  schedule: 'schedule',
  collection: 'collection',
  user: 'user',
  dashboards: 'dashboards',
  dashboard_users: 'dashboard_users',
  task: 'task',
  ticket: 'ticket',
};

export type ImportTypeEnum = keyof typeof ImportTypes;
