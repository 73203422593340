import { FiInfo } from 'react-icons/fi';
import styled, { css } from 'styled-components';

// Container

export const QuestionContainer = styled.div`
  flex: 1;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

// Header

export const QuestionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Title
interface TextProps {
  hasError?: boolean;
}

export const QuestionTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme }) => theme.colors.darkText};
  font-size: 14px;
  font-weight: 400;
`;

export const QuestionSubtitle = styled.div<TextProps>`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme, hasError }) =>
    hasError ? theme.colors.error : theme.colors.lightText};
  font-size: 10px;
  font-weight: normal;

  animation: ${({ hasError }) => (hasError ? 'show-subtitle' : 'hide-subtitle')}
    200ms;
  @keyframes hide-subtitle {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-12px);
    }
  }

  @keyframes show-subtitle {
    from {
      transform: translateY(-12px);
    }
    to {
      transform: translateY(0);
    }
  }
`;

// Input

export interface InputProps {
  hasError?: boolean;
  warning?: boolean;
  onFocusEvent?: boolean;
  backgroundColor?: string;
}

export const InputDiv = styled.div<InputProps>`
  width: 100%;
  outline: none;
  padding: 8px 10px;
  border: ${({ backgroundColor }) => (backgroundColor ? "1px solid #d4d4d6" : 0)};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.v2_secondary};
  border-radius: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme, hasError, warning }) => {
    if (warning) return `box-shadow: 0 0 0 1px ${theme.colors.warning};`;
    if (hasError) return `box-shadow: 0 0 0 1px ${theme.colors.error};`;
    return null;
  }}

  & > svg {
    display: none;
  }

  transition: 200ms;
  :hover {
    ${({ theme, hasError, warning }) => {
      if (warning) return `box-shadow: 0 0 0 1px ${theme.colors.warning};`;
      if (hasError) return `box-shadow: 0 0 0 1px ${theme.colors.error};`;
      return `box-shadow: 0 0 0 1px ${theme.colors.primary};`;
    }}
    background-color: ${({ theme }) => theme.colors.lighterText};
    & > svg {
      display: block;
    }
  }

  ${({ onFocusEvent }) => onFocusEvent && '& > svg { display: block; }'}

  ${({ theme, onFocusEvent, hasError, warning }) => {
    if (onFocusEvent) {
      if (warning)
        return `box-shadow: 0 0 0 1px ${theme.colors.warning}; background-color: ${theme.colors.lighterText};`;
      if (hasError)
        return `box-shadow: 0 0 0 1px ${theme.colors.error}; background-color: ${theme.colors.lighterText};`;
      return `box-shadow: 0 0 0 1px ${theme.colors.primary}; background-color: ${theme.colors.lighterText};`;
    }
    return null;
  }}
`;

export const Input = styled.input`
  width: 100%;
  outline: none;
  border: 0;
  padding: 0;
  background-color: transparent;

  font-family: ${({ theme }) => theme.fonts.primaryFont};
  color: ${({ theme }) => theme.colors.darkText};
  font-size: 12px;
  font-weight: 400;
`;

// Tooltip

export const TooltipContainer = styled.div``;

export const InfoTooltip = styled(FiInfo)`
  color: ${({ theme }) => theme.colors.mediumText};
  font-size: 8;
`;
