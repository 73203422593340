import { DiscussionTopic } from '@/hooks/Feedback/interfaces';
import { useFeedbackReactions } from '@/hooks/Feedback/useFeedbackReactions';
import { EventParentableType } from '@/modules/core/domain/interfaces/IFormulary';
import { QuestionContent } from '@/screens/monitoring/EventReports/components/Question/QuestionContent/QuestionContent';
import { openInNewTab } from '@/utils/openInNewTab';
import { ExternalLinkIcon, LinkIcon } from '@chakra-ui/icons';
import {
  Badge,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Icon,
  IconButton,
  Tag,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiThumbsDown, FiThumbsUp } from 'react-icons/fi';

import { FeedbackAttachmentsPane } from './FeedbackAttachmentsPane';

type FeedbackQuestionPreviewProps = {
  feedback: DiscussionTopic;
};

const GetTypeTagColor = (type: EventParentableType) => {
  switch (type) {
    case 'Task':
      return '#043C73';
    case 'Schedule':
      return '#AB697B';

    default:
      return 'gray';
  }
};

const Details: React.FC<FeedbackQuestionPreviewProps> = ({ feedback }) => {
  const { question, formularyName, eventId, eventParentableType } = feedback;
  const isFlagged = question?.answers.some((e) => e.isFlagged);
  const { t } = useTranslation();

  const answeredAt = question?.answers[0]?.answeredAt;

  const openExternal = React.useCallback(() => {
    openInNewTab(`${window.location.origin}/external/event/${eventId}`);
  }, [eventId]);

  return (
    <Flex
      w="full"
      flexDir="row"
      justify="space-between"
      alignItems="flex-start"
    >
      <Flex w="full" flexDir="column" gap="2">
        <HStack alignItems="flex-start" justify="space-between">
          <Text
            wordBreak="break-all"
            color="gray.400"
            fontWeight="bold"
            fontSize="sm"
          >
            {`${formularyName} / ${question?.section} / ${moment(
              answeredAt,
            ).format('hh:mm a')}`}
          </Text>
          <Flex
            alignItems={{ base: 'flex-end', md: 'center' }}
            flexDir={{ base: 'column', md: 'row' }}
            gap="2"
          >
            <Badge
              hidden={!isFlagged}
              w="min-content"
              variant="outline"
              colorScheme="orange"
              mr={5}
            >
              Sinalizado como inconforme
            </Badge>
          </Flex>
        </HStack>

        <Text
          wordBreak={{ base: 'break-all', md: 'break-word' }}
          color="darkText"
          fontWeight="bold"
          fontSize="15px"
          lineHeight={1}
        >
          {question?.question}
        </Text>
      </Flex>
      <HStack spacing={15}>
        <Tag
          size="lg"
          variant="solid"
          bgColor={GetTypeTagColor(eventParentableType)}
          height="12"
        >
          {t(`feedbacks.${eventParentableType}`)}
        </Tag>
        <IconButton
          size="lg"
          aria-label="open"
          icon={<ExternalLinkIcon />}
          onClick={openExternal}
        />
      </HStack>
    </Flex>
  );
};

const Footer: React.FC<FeedbackQuestionPreviewProps> = ({ feedback }) => {
  const { isLoading, onDislike, onLike, topic } = useFeedbackReactions({
    topicId: feedback.id,
    topic: feedback,
  });

  const userReaction = topic?.voteCurrentUser;

  const [paneOpen, setPaneOpen] = React.useState(false);

  React.useEffect(() => {
    setPaneOpen(false);
  }, [feedback]);

  const likesAmount = React.useMemo(() => topic?.countLikes || 0, [
    topic?.countLikes,
  ]);
  const dislikesAmount = React.useMemo(() => topic?.countDislikes || 0, [
    topic?.countDislikes,
  ]);

  return (
    <>
      <Flex borderTopWidth="thin" p="4" justifyContent="space-between">
        <ButtonGroup size="md" variant="outline">
          <Button
            onClick={onLike}
            disabled={isLoading}
            leftIcon={<Icon as={FiThumbsUp} fontSize="15px" />}
            aria-label="like"
            colorScheme={userReaction === 'like' ? 'green' : 'gray'}
            variant={userReaction === 'like' ? 'subtle' : 'outline'}
          >
            {likesAmount}
          </Button>
          <Button
            onClick={onDislike}
            disabled={isLoading}
            leftIcon={<Icon as={FiThumbsDown} fontSize="15px" />}
            aria-label="dislike"
            colorScheme={userReaction === 'dislike' ? 'red' : 'gray'}
            variant={userReaction === 'dislike' ? 'subtle' : 'outline'}
          >
            {dislikesAmount}
          </Button>
        </ButtonGroup>

        <Button
          colorScheme="gray"
          variant="outline"
          size="md"
          onClick={() => setPaneOpen(!paneOpen)}
          rightIcon={<LinkIcon />}
        >
          {feedback?.question?.itemAttachments &&
          feedback?.question?.itemAttachments.length > 0
            ? `${feedback.question.itemAttachments.length} ${
                feedback.question.itemAttachments.length > 1
                  ? 'Anexos'
                  : 'Anexo'
              }`
            : 'Anexos'}
        </Button>
      </Flex>

      {feedback?.question?.id && (
        <FeedbackAttachmentsPane
          paneOpen={paneOpen}
          selectedItemId={feedback.itemId}
          questionId={feedback.question.id}
        />
      )}
    </>
  );
};

const FeedbackQuestionPreview: React.FC<FeedbackQuestionPreviewProps> = (
  props,
) => {
  const { feedback } = props;
  if (!feedback?.question) return null;
  return (
    <Flex
      overflow="clip"
      boxShadow="initial"
      flexDir="column"
      borderWidth="thin"
      w="full"
      rounded="xl"
      bg="white"
    >
      <Flex p="3.5" gap={4} flexDir="column">
        <Details feedback={feedback} />
        <Flex w="full">
          <QuestionContent question={feedback.question!} />
        </Flex>
        <Flex
          flexDir="column"
          gap="1"
          hidden={!feedback.question?.answers?.[0]?.comment}
        >
          <Text fontWeight="bold">Notas:</Text>
          <HStack>
            <Flex
              p="2"
              textAlign="start"
              minH="10"
              rounded="lg"
              w="full"
              bg="gray.100"
              overflow="auto"
            >
              {feedback.question.answers?.[0]?.comment}
            </Flex>
          </HStack>
        </Flex>
      </Flex>
      <Footer feedback={feedback} />
    </Flex>
  );
};

export default FeedbackQuestionPreview;
