import { useDebouncedSearch } from '@/components/DashboardCard/utils/useDebouncedSearch';
import { gql } from '@/gql';
import { useModals } from '@/hooks/ModalManager';
import { createContext } from '@/utils/react-utils';
import { useQuery } from '@apollo/client';
import { RowSelectionState } from '@tanstack/react-table';
import { isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDeletePublicTasksBatch } from './usePublicTasksMutations';

export const publicTasksQuery = gql(`#graphql
  query PublicTasks($first: Int, $last: Int, $before: String, $after: String, $status: TaskTypes, $search: String, $filters: JSON) {
    publicTasks(first: $first, last: $last, before: $before, after: $after, status: $status, search: $search, filters: $filters) {
      edges {
        cursor
        node {
          id
          active
          templateTaskId
          updatedAt
          status
          publicTask
          name
          originType
          originProperty
          originId
          localId
          memberId
          member {
            id
            email
            fullName
            username
          }
          eventId
          finishedAt
          event {
            id
            finishedAt
            status
            updatedAt
          }

          templateUrlEmail {
            id
            author {
              id
              fullName
            }
            email
          }
          templateTask {
            id
            formulary {
              id
              name
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`);

export function usePublicTasks() {
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>();
  const [filters, setFilters] = React.useState<any>();
  const { t } = useTranslation();
  const modals = useModals();
  const [search, setSearch] = useDebouncedSearch({ debounceRate: 1000 });

  const { data, loading, fetchMore, refetch } = useQuery(publicTasksQuery, {
    variables: {
      first: 10,
      search,
      filters,
    },
    pollInterval: 10000,
  });

  React.useEffect(() => {
    refetch({
      first: 10,
      search,
      filters,
    });
  }, [filters, search, refetch]);

  const [deleteTasks] = useDeletePublicTasksBatch({
    refetchQueries: [publicTasksQuery],
  });

  const items = React.useMemo(
    () =>
      orderBy(
        data?.publicTasks?.edges.map((e) => e.node!),
        'finishedAt',
        'desc',
      ) || [],
    [data?.publicTasks?.edges],
  );

  const paginate = React.useCallback(() => {
    fetchMore({
      variables: {
        after: data?.publicTasks?.pageInfo.endCursor,
        first: 45,
      },
    });
  }, [data?.publicTasks?.pageInfo.endCursor, fetchMore]);

  const onDeleteTasks = React.useCallback(() => {
    const rowIndexes = rowSelection ? Object.keys(rowSelection) : [];

    const ids = rowIndexes.reduce((prev, curr) => {
      const item = items[(curr as unknown) as number];
      if (item) {
        return [...prev, item.id];
      }
      return prev;
    }, [] as string[]);
    if (!ids.length) return;
    modals.confirm({
      title: t('error.warning'),
      body: t('messageDelete.ask'),
      onConfirm() {
        deleteTasks(ids);
        setRowSelection({});
      },
    });
  }, [deleteTasks, items, modals, rowSelection, t]);

  return {
    items,
    onDeleteTasks,
    pageInfo: data?.publicTasks?.pageInfo,
    rowSelection,
    setRowSelection,
    loading,
    setSearch,
    paginate,
    fetchMore,
    setFilters,
  };
}

type UsePublicTasksContext = ReturnType<typeof usePublicTasks>;

export const [PublicTasksContext, usePublicTasksContext] = createContext<
  UsePublicTasksContext
>();
