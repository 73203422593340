import { FormView } from '@/components_v2/WebForm/components';
import {
  InspectionStoreProvider,
  useInspectionStore,
} from '@/components_v2/WebForm/context/store';
import { WebFormPropsProvider } from '@/components_v2/WebForm/hooks/useWebFormProps';
import { parseFormularyToFields } from '@/components_v2/WebForm/utils/parseFormularyToFields';
import { useModals } from '@/hooks/ModalManager';
import { useWorkspace } from '@/hooks/workspace';
import { WebFormProvider } from '@/modules/WebForm/data/formState';
import useWebForm from '@/modules/WebForm/hooks/useWebForm';
import useSerializedSubmit, {
  SerializeCallbackFunc,
} from '@/screens/ItemEdit/hooks/useSerializedSubmit';
import { createContext } from '@/utils/react-utils';
import { ReactComponent as FocoLogo } from '@assets/foco-logo.svg';
import { ReactComponent as MisteryLogo } from '@assets/mistery-logo.svg';
import { ReactComponent as UndrawIcon } from '@assets/undraw_create_re_57a3.svg';
import { ReactComponent as WeCheckIcon } from '@assets/wc-logo-inline.svg';
import { AtSignIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BiError } from 'react-icons/bi';
import { FaScissors } from 'react-icons/fa6';
import { IoMdClipboard } from 'react-icons/io';
import { Prompt } from 'react-router-dom';
import * as yup from 'yup';

import {
  useExternalTaskForm,
  useSendPdf,
  useSubmitAnswersTemplateTask,
} from '../hooks/useExternalTask';

type Props = {
  templateTaskUrlId: string;
  email?: string;
};

function useExternalTask(props: Props) {
  const { t } = useTranslation();
  const { templateTaskUrlId, email } = props;
  const modals = useModals();
  const toast = useToast();
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [submitAnswers, submitPayload] = useSubmitAnswersTemplateTask();
  const { data, loading, error } = useExternalTaskForm(templateTaskUrlId);
  const [sendPdf, sendPdfPayload] = useSendPdf();
  const { workspaceData } = useWorkspace();

  const isDisabled =
    data?.publicTemplateTaskUrl?.id && !data.publicTemplateTaskUrl.active;

  const eventId = submitPayload.data?.submitAnswersTemplateTaskUrl?.event?.id;

  const handleSubmit: SerializeCallbackFunc = React.useCallback(
    (params) => {
      if (!params.isValid) {
        modals.alert({
          title: 'Este checklist contém respostas pendentes ou inválidas!',
          body: 'Preencha todos os campos para continuar.',
          closeOnConfirm: true,
        });
      } else {
        modals.confirm({
          title: 'Deseja submeter estas respostas?',
          body: 'Esta ação é irreversível.',
          confirmLabel: 'Sim',
          cancelLabel: 'Continuar editando',
          onConfirm: async () => {
            if (!data?.publicTemplateTaskUrl?.id) return;
            submitAnswers({
              variables: {
                input: {
                  email: email || '',
                  answers: params.answers.map((answer) => ({
                    id: answer.id,
                    answeredAt: answer.answeredAt?.getTime(),
                    content: `${answer.content}`,
                    collectionItemId: answer.collectionItemId,
                    questionId: `${answer.questionId}`,
                  })),
                  templateTaskUrlId: data?.publicTemplateTaskUrl?.id,
                },
              },
              onCompleted() {
                setHasSubmitted(true);
              },
              onError(error) {
                toast({
                  status: 'error',
                  title: t('error.warning'),
                  description: error.message,
                });
              },
            });
          },
        });
      }
    },
    [data?.publicTemplateTaskUrl?.id, email, modals, submitAnswers, t, toast],
  );

  const handleSubmitEmail = React.useCallback(
    (emailRecipient: string) => {
      if (!eventId) return;
      sendPdf({
        variables: {
          input: {
            email: emailRecipient,
            description:
              workspaceData.name === 'foco'
                ? 'Devolução confirmada - A presente vistoria será enviada para análise pela equipe responsável. Havendo constatação de avarias ocorridas no curso da sua locação, constantes no laudo de vistoria, eventuais valores de ressarcimento poderão ser cobrados, conforme disposto nos termos e condições gerais da locação.'
                : '',
            eventId,
          },
        },
      });
    },
    [eventId, sendPdf, workspaceData],
  );

  return {
    data,
    error,
    loading,
    isDisabled,
    email,
    submitting: submitPayload.loading,
    submitError: submitPayload.error,
    sendPdfPayload,
    event: submitPayload.data?.submitAnswersTemplateTaskUrl?.event,
    handleSubmitEmail,
    handleSubmit,
    hasSubmitted,
  };
}

type UseExternalForm = ReturnType<typeof useExternalTask>;

const [ExternalTaskProvider, useExternalTaskContext] = createContext<
  UseExternalForm
>();

function useInitializeForm() {
  const initialized = React.useRef(false);
  const { data, loading } = useExternalTaskContext();
  const { setLoading, setForm } = useInspectionStore();
  const { setFields } = useWebForm();

  React.useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const initialize = React.useCallback(() => {
    if (
      !data?.publicTemplateTaskUrl?.templateTask?.formulary ||
      initialized.current
    )
      return;
    const { formulary } = data?.publicTemplateTaskUrl?.templateTask;
    setForm({ ...formulary } as any);
    if (formulary) {
      setFields(parseFormularyToFields(formulary as any));
    }
    initialized.current = true;
  }, [data?.publicTemplateTaskUrl?.templateTask, setFields, setForm]);

  React.useEffect(() => {
    initialize();
  }, [initialize]);
}

const FormContent = () => {
  useInitializeForm();
  const { data } = useExternalTaskContext();
  return (
    <FormView
      px={{ base: '4' }}
      formDescription={data?.publicTemplateTaskUrl?.description}
    />
  );
};

const Footer = () => {
  return (
    <Flex
      w="full"
      borderTopWidth="thin"
      bg="white"
      alignSelf="flex-end"
      h="20"
      p="4"
      alignItems="center"
      justifyContent="center"
    >
      <Text fontWeight="bold">Powered By</Text>
      <Link href="https://wecheckapp.com.br/" isExternal>
        <WeCheckIcon width="auto" height="50px" />
      </Link>
    </Flex>
  );
};

const formPdfSchema = yup
  .object({
    email: yup
      .string()
      .email('O e-mail não é válido')
      .required('Digite um e-mail'),
    description: yup.string(),
  })
  .required();

const FormSendPdf = () => {
  const {
    email: _email,
    sendPdfPayload,
    handleSubmitEmail,
  } = useExternalTaskContext();
  const [email, setEmail] = React.useState('');
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const form = useForm<yup.InferType<typeof formPdfSchema>>({
    resolver: yupResolver(formPdfSchema),
    defaultValues: {
      email: _email,
    },
  });
  const { workspaceData } = useWorkspace();

  return (
    <Flex
      w="full"
      as="form"
      flexDir="column"
      gap="2"
      onSubmit={form.handleSubmit(async (payload) => {
        handleSubmitEmail(payload.email);
        setEmail(payload.email);
        form.reset();
      })}
    >
      <Divider my="2" />
      <Text alignSelf="flex-start" fontSize="xl" fontWeight="semibold">
        {workspaceData.name === 'misteryshopper'
          ? 'Recibir copia por e-mail'
          : 'Receber cópia via e-mail'}
      </Text>
      <FormControl isInvalid={!!form.formState.errors.email}>
        <InputGroup w="full" size="lg">
          <InputLeftElement pointerEvents="none">
            <AtSignIcon color="gray.500" />
          </InputLeftElement>
          <Input
            autoFocus
            {...form.register('email')}
            variant="filled"
            size="lg"
            fontSize="xl"
            _placeholder={{
              color: 'gray.700',
            }}
            disabled={sendPdfPayload.loading}
            placeholder="jon@doe.com"
          />
          <InputRightElement w="auto" pe="1">
            <Button
              isLoading={sendPdfPayload.loading}
              ref={buttonRef}
              type="submit"
              size="md"
              colorScheme="brand"
              variant="solid"
            >
              Enviar
            </Button>
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>
          {form.formState.errors.email?.message}
        </FormErrorMessage>
      </FormControl>
      {!!sendPdfPayload.called &&
        !!sendPdfPayload.data?.templateTaskUrlSendEmailPdf?.eventId && (
          <Alert
            textAlign="start"
            alignItems="flex-start"
            variant="subtle"
            status="success"
            rounded="md"
          >
            <AlertIcon />
            <AlertTitle>Enviado</AlertTitle>
            <AlertDescription>
              Você receberá em <strong>{email}</strong> em breve
            </AlertDescription>
          </Alert>
        )}
    </Flex>
  );
};

const FormDone = () => {
  const { event } = useExternalTaskContext();
  const { workspaceData } = useWorkspace();

  const getLogo = () => {
    switch (workspaceData.name) {
      case 'foco':
        return <FocoLogo width="20vw" height="auto" />;
      case 'misteryshopper':
        return <MisteryLogo width="20vw" height="auto" />;

      default:
        return <UndrawIcon width="40vw" height="auto" />;
    }
  };

  const getTitleText = () => {
    switch (workspaceData.name) {
      case 'foco':
        return 'Foco Aluguel de Carros - Registro de devolução';
      case 'misteryshopper':
        return '¡Gracias por responder a este checklist!';

      default:
        return 'Obrigado por responder a este checklist!';
    }
  };

  const getDescriptionText = () => {
    switch (workspaceData.name) {
      case 'foco':
        return 'Devolução confirmada - A presente vistoria será enviada para análise pela equipe responsável. Havendo constatação de avarias ocorridas no curso da sua locação, constantes no laudo de vistoria, eventuais valores de ressarcimento poderão ser cobrados, conforme disposto nos termos e condições gerais da locação.';
      case 'misteryshopper':
        return 'Puedes cerrar esta ventana, tus datos se guardan.';

      default:
        return 'Você pode fechar esta janela, seus dados estão salvos.';
    }
  };

  return (
    <AnimatePresence>
      <Flex flex={1} flexDir="column">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0, scale: 0.5 }}
          style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
        >
          <Center p="6" flex="1">
            <Flex
              textAlign="center"
              flexDir="column"
              gap="4"
              alignItems="center"
              boxShadow="lg"
              rounded="lg"
              borderWidth="thin"
              p={{ base: 4, lg: '14' }}
            >
              {getLogo()}

              <Flex
                w="100%"
                justifyContent={{ base: 'center', xl: 'space-around' }}
                direction={{ base: 'column', xl: 'row' }}
                alignItems="center"
                gap={5}
              >
                {event?.pointsResult && (
                  <>
                    <Flex
                      bg="gray.100"
                      p={5}
                      w="40%"
                      minWidth={80}
                      borderRadius={10}
                      gap={5}
                    >
                      <Icon as={FaScissors} fontSize={50} color="purple.600" />
                      <Box>
                        <Text textAlign="start" color="gray.500" fontSize={15}>
                          Pontos recebidos
                        </Text>
                        <Text textAlign="start" fontWeight={900} fontSize={15}>
                          {event?.pointsResult.score} de 10
                        </Text>
                      </Box>
                    </Flex>
                    <Flex
                      bg="gray.100"
                      p={5}
                      w="40%"
                      minWidth={80}
                      borderRadius={10}
                      gap={5}
                    >
                      <Icon
                        as={IoMdClipboard}
                        fontSize={50}
                        color={
                          event?.pointsResult?.approved ? 'limegreen' : 'red'
                        }
                      />
                      <Box>
                        <Text textAlign="start" color="gray.500" fontSize={15}>
                          Status
                        </Text>
                        <Text textAlign="start" fontWeight={900} fontSize={15}>
                          {event?.pointsResult?.approved
                            ? 'Aprovado'
                            : 'Reprovado'}
                        </Text>
                      </Box>
                    </Flex>
                  </>
                )}
              </Flex>

              <Box>
                <Text fontSize="3xl" fontWeight="semibold">
                  {getTitleText()}
                </Text>
                <Text fontSize="lg" fontWeight="semibold">
                  {getDescriptionText()}
                </Text>
              </Box>
              <FormSendPdf />
            </Flex>
          </Center>
        </motion.div>
        <Footer />
      </Flex>
    </AnimatePresence>
  );
};

const Content = () => {
  const {
    loading,
    handleSubmit,
    hasSubmitted,
    error,
    isDisabled,
  } = useExternalTaskContext();

  const onSubmit = useSerializedSubmit(handleSubmit);

  if (error || isDisabled) {
    return (
      <VStack flex="1">
        <Center flex={1} w="full">
          <VStack>
            <Icon as={BiError} fontSize={90} color="gray.600" />
            <Heading size="3xl" color="gray.600">
              Não encontrado
            </Heading>
            <Text fontSize="xl">
              O link acessado é inválido, ou já expirou.
            </Text>
          </VStack>
        </Center>
        <Footer />
      </VStack>
    );
  }

  if (hasSubmitted) {
    return <FormDone />;
  }

  if (loading) {
    return (
      <Center h="100vh" flexDir="column" display="flex" gap="4" w="100vw">
        <Spinner size="xl" />
        <Text fontSize="2xl" fontWeight="bold">
          Aguarde
        </Text>
      </Center>
    );
  }

  return (
    <WebFormPropsProvider formularyId="" itemId="" noHeader onSubmit={onSubmit}>
      <Box minH="100vh" pb="10" w="100vw" overflowY="auto" h="full">
        <FormContent />
        <Footer />
      </Box>
    </WebFormPropsProvider>
  );
};

const Providers = ({
  children,
  ...rest
}: { children: React.ReactNode } & Props) => {
  const hook = useExternalTask(rest);

  return (
    <InspectionStoreProvider>
      <ExternalTaskProvider value={hook}>
        <WebFormProvider>{children}</WebFormProvider>
      </ExternalTaskProvider>
    </InspectionStoreProvider>
  );
};

export function ExternalTask(props: Props) {
  return (
    <Providers {...props}>
      <Prompt when message="Are you sure you want to leave?" />
      <Content />
    </Providers>
  );
}
