import { QuestionAttachments } from '@/components_v2/QuestionAttachments/QuestionAttachments';
import { useDefaultAttachmentsHandler } from '@/components_v2/QuestionAttachments/useDefaultAttachmentsHandler';
import { withUploadProvider } from '@/hooks/upload/uploadProvider';
import { attachmentsStorage } from '@/modules/shared/adapters/CloudStorage';
import { Collapse, Flex } from '@chakra-ui/react';
import React from 'react';

const makeAttachmentsHook = (payload: {
  questionId: string;
  itemId: string;
}) => {
  return () => useDefaultAttachmentsHandler(payload);
};

export function Content({
  questionId,
  selectedItemId,
  paneOpen,
}: {
  questionId: string;
  selectedItemId: string;
  paneOpen: boolean;
}): JSX.Element {
  const useUploader = React.useCallback(() => {
    return makeAttachmentsHook({ itemId: `${selectedItemId}`, questionId });
  }, [questionId, selectedItemId]);

  return (
    <Collapse in={paneOpen}>
      <Flex bg="gray.50" borderTopWidth="thin" p="3" flex="1">
        <QuestionAttachments useUploader={useUploader()} />
      </Flex>
    </Collapse>
  );
}

export const FeedbackAttachmentsPane = withUploadProvider(attachmentsStorage)(
  Content,
);
