import { ReactComponent as WeCheckIcon } from '@assets/wc-logo-inline.svg';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import styled, { css } from 'styled-components';

type OptionalProps = {
  styleOnHover?: boolean;
  pointer?: boolean;
  center?: boolean;
};

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.background};
`;

export const Logo = styled(WeCheckIcon)`
  position: relative;
  width: ${42 * 8}px;
  height: ${19 * 8}px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.darkText};
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  opacity: 0.8;
  font-size: 36px;
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.div`
  margin-top: 5px;
  color: #454245;
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  margin-bottom: 25px;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const OpenEyeIcon = styled(FiEye)`
  font-size: 18px;
  right: 15px;
  top: 15px;
  color: ${({ theme }) => theme.colors.darkText};
  position: absolute;
  cursor: pointer;
  opacity: 0.5;
`;

export const ClosedEyeIcon = styled(FiEyeOff)`
  font-size: 18px;
  right: 15px;
  top: 15px;
  color: ${({ theme }) => theme.colors.darkText};
  position: absolute;
  cursor: pointer;
  opacity: 0.5;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 370px;
  align-items: center;
  justify-content: center;
  @media (max-width: 530px) {
    width: 90%;
  }
`;

export const PasswordContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const SignIn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    width: 398px;
    height: 515px;
    border-radius: 10px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  @media (max-width: 530px) {
    margin-bottom: 30%;
    flex-direction: column;
  }
`;

export const FooterLinks = styled.a`
  align-self: center;
  margin: 0px 15px 0px 0px;
  font-size: 15px;
  font-weight: 300;
  color: #696969;
  letter-spacing: -0.2px;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  @media (max-width: 530px) {
    margin: 10px 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 200px;
    min-width: 200px;
    box-sizing: border-box;
  }
`;

export const SupportText = styled.p<OptionalProps>`
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.darkText};
  opacity: 0.6;
  transition: 0.2s;
  ${({ center }) =>
    center ? 'text-align: center;' : 'align-self: flex-start;'}
  ${({ pointer }) => (pointer ? 'cursor: pointer;' : null)}
  ${({ styleOnHover }) => {
    if (!styleOnHover) return null;
    return css`
      :hover {
        text-decoration: underline;
      }
    `;
  }}
`;
