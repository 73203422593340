import Lightbox from '@/components/Lightbox';
import {
  CIRCULABI_SIGNOFF_QUESTION_TYPE,
  ViewCirculabiSignoffResult,
} from '@/modules/circulabi';
import { Answer } from '@/modules/core/domain/interfaces/IFormulary';
import parseSecondsToTime from '@/utils/parseSecondsToTime';
import {
  Box,
  Center,
  Flex,
  HStack,
  Icon,
  Image,
  List,
  ListItem,
  Spinner,
  Square,
  Tag,
  Text,
  Tooltip,
  Wrap,
} from '@chakra-ui/react';
import GoogleMapReact from 'google-map-react';
import JsBarcode from 'jsbarcode';
import moment from 'moment';
import React from 'react';
import { BiStopwatch } from 'react-icons/bi';
import { BsImage } from 'react-icons/bs';
import { FiClock } from 'react-icons/fi';
import { RiMapPin2Fill } from 'react-icons/ri';

import { ItemQuestion } from '../../../interfaces';

// import { Container } from './styles';

type QuestionRenderProps = { question: ItemQuestion };
export type QuestionRenderType = React.FC<QuestionRenderProps>;

const useSingleAnswer = (question: ItemQuestion) => {
  const parsedAnswer = React.useMemo(() => question.answers[0], [
    question.answers,
  ]);

  return parsedAnswer;
};

const RadioButtonContent: QuestionRenderType = (props) => {
  const { question } = props;
  const { options } = question;
  const { optionId, isFlagged } = useSingleAnswer(question);

  const parsedAnswer = React.useMemo(
    () => options.find((e) => e.id === optionId),
    [optionId, options],
  );
  return (
    <Tag
      borderWidth={isFlagged ? 'none' : 'thin'}
      size="lg"
      variant={isFlagged ? 'solid' : 'subtle'}
      colorScheme={isFlagged ? 'orange' : 'gray'}
    >
      {parsedAnswer?.name}
    </Tag>
  );
};

const UserContent: QuestionRenderType = (props) => {
  const { question } = props;
  const { content } = useSingleAnswer(question);

  const parsedAnswer = React.useMemo(() => JSON.parse(content), [content]);

  return (
    <Tag borderWidth="thin" size="lg" variant="subtle" colorScheme="gray">
      {parsedAnswer?.name}
    </Tag>
  );
};

const CheckboxContent: QuestionRenderType = (props) => {
  const { question } = props;
  const { answers, options } = question;

  const renderAnswer = React.useCallback(
    (answer: Answer) => {
      const { optionId, isFlagged } = answer;
      const option = options.find((e) => e.id === optionId);

      if (!option) return null;
      return (
        <Tag
          borderWidth={isFlagged ? 'none' : 'thin'}
          size="lg"
          variant={isFlagged ? 'solid' : 'subtle'}
          colorScheme={isFlagged ? 'orange' : 'gray'}
        >
          {option?.name}
        </Tag>
      );
    },
    [options],
  );
  return (
    <List spacing="3">
      {answers.map((answer) => (
        <ListItem key={answer.id}>{renderAnswer(answer)}</ListItem>
      ))}
    </List>
  );
};

const TextContent: QuestionRenderType = (props) => {
  const { question } = props;
  const { content } = useSingleAnswer(question);

  return (
    <Box borderWidth="thin" w="full" overflow="clip" rounded="md" p="3">
      <Text fontSize="lg" fontWeight="bold" color="mutedText">
        {content}
      </Text>
    </Box>
  );
};

const ImageContent: QuestionRenderType = (props) => {
  const { question } = props;
  const { answers } = question;

  const onLightbox = React.useCallback(
    (index: number) => {
      Lightbox.openLightbox(
        answers.map((answer) => answer.fileUrl || answer.content),
        index,
      );
    },
    [answers],
  );

  const renderAnswer = React.useCallback(
    (answer: Answer, index: number) => {
      const { content } = answer;

      return (
        <Square
          key={answer.id}
          cursor="pointer"
          onClick={() => onLightbox(index)}
          borderWidth="thin"
          size="52"
          overflow="clip"
          rounded="md"
        >
          {answer?.mediaProcessed ? (
            <Image
              boxSize="full"
              objectFit="cover"
              src={content}
              alt="Imagem"
            />
          ) : (
            <Tooltip label="A mídia está processando, ou ainda está sendo carregada no dispositivo que a originou. Por favor, aguarde.">
              <Center bg="gray.50" h="full" w="full" flexDir="column">
                <Text>Processando mídia.</Text>
                <BsImage size="18" />
              </Center>
            </Tooltip>
          )}
        </Square>
      );
    },
    [onLightbox],
  );
  return <Wrap>{answers.map(renderAnswer)}</Wrap>;
};

const ChronometerContent: QuestionRenderType = (props) => {
  const { question } = props;
  const { content } = useSingleAnswer(question);

  const time = parseSecondsToTime(content, true);

  return (
    <HStack
      fontSize="lg"
      fontWeight="bold"
      color="mutedText"
      borderWidth="thin"
      w="full"
      overflow="clip"
      rounded="md"
      p="3"
    >
      <Icon as={BiStopwatch} />
      <Text>{time}</Text>
    </HStack>
  );
};

const TimePickerContent: QuestionRenderType = (props) => {
  const { question } = props;
  const { content } = useSingleAnswer(question);

  const time = parseSecondsToTime(content);

  return (
    <HStack
      fontSize="lg"
      fontWeight="bold"
      color="mutedText"
      borderWidth="thin"
      w="full"
      overflow="clip"
      rounded="md"
      p="3"
    >
      <Icon as={FiClock} />
      <Text>{time}</Text>
    </HStack>
  );
};

const DateContent: QuestionRenderType = (props) => {
  const { question } = props;
  const { content } = useSingleAnswer(question);

  return (
    <HStack
      fontSize="lg"
      fontWeight="bold"
      color="mutedText"
      borderWidth="thin"
      w="full"
      overflow="clip"
      rounded="md"
      p="3"
    >
      <Icon as={FiClock} />
      <Text>
        {!!content &&
          moment(content * 1000).format('dddd, DD [de] MMMM [de] YYYY')}
      </Text>
    </HStack>
  );
};

const GeolocationContent: QuestionRenderType = (props) => {
  const { question } = props;
  const { content } = useSingleAnswer(question);
  const [map, setMap] = React.useState<any>();
  const mapRef = React.useRef<GoogleMapReact>(null);

  const location: { latitude: number; longitude: number } = React.useMemo(
    () => JSON.parse(content),
    [content],
  );
  React.useEffect(() => {
    if (location && map) {
      map.setCenter({
        lat: location.latitude,
        lng: location.longitude,
      });
      map.setZoom(18);
    }
  }, [location, map]);

  if (!location.latitude) return null;

  return (
    <Flex flexDir="column" w="100%" gap={5}>
      <Box overflow="clip" rounded="lg" w="100%" h="72">
        <GoogleMapReact
          ref={mapRef}
          onGoogleApiLoaded={({ map }) => setMap(map)}
          defaultCenter={{ lat: -14.235, lng: -51.9253 }}
          defaultZoom={18}
          yesIWantToUseGoogleMapApiInternals
        >
          <Box {...{ lat: location.latitude, lng: location.longitude }}>
            <Flex
              flexDir="column"
              alignItems="center"
              justifyContent="flex-start"
              width="3em"
              height="3em"
              position="relative"
            >
              <Icon
                position="absolute"
                top="-100%"
                left="-50%"
                as={RiMapPin2Fill}
                color="red.500"
                width="3em"
                height="3em"
              />
            </Flex>
          </Box>
        </GoogleMapReact>
      </Box>
      <Text
        fontWeight="bold"
        color="darkText"
      >{`Latitude: ${location.latitude}, Longitude: ${location.longitude}`}</Text>
    </Flex>
  );
};

const SignatureContent: QuestionRenderType = (props) => {
  const { question } = props;
  const { content } = useSingleAnswer(question);

  return (
    <Box borderWidth="thin" w="full" overflow="clip" rounded="md">
      <Image boxSize="full" objectFit="scale-down" src={content} alt="Imagem" />
    </Box>
  );
};

const CollectionItemContent: QuestionRenderType = (props) => {
  const { question } = props;
  const { content } = useSingleAnswer(question);

  return (
    <Box w="full" overflow="clip" rounded="md">
      <Tag size="lg" colorScheme="purple">
        {content}
      </Tag>
    </Box>
  );
};

export const CollectionComboContent: QuestionRenderType = (props) => {
  const { question } = props;
  const { answers } = question;
  const renderAnswer = React.useCallback((answer: Answer) => {
    const { content, isFlagged } = answer;

    if (!content) return null;
    return (
      <Tag
        borderWidth={isFlagged ? 'none' : 'thin'}
        size="lg"
        variant={isFlagged ? 'solid' : 'subtle'}
        colorScheme={isFlagged ? 'orange' : 'purple'}
      >
        {content}
      </Tag>
    );
  }, []);
  return (
    <List spacing="3">
      {answers.map((answer) => (
        <ListItem key={answer.id}>{renderAnswer(answer)}</ListItem>
      ))}
    </List>
  );
};

const BarcodeContent: QuestionRenderType = ({ question }) => {
  const { content } = useSingleAnswer(question);
  const [code] = React.useState(content);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    if (canvasRef.current) {
      JsBarcode(canvasRef.current, code);
    }
  }, [code]);

  return (
    <Box
      borderWidth="thin"
      w="full"
      overflow="clip"
      rounded="md"
      display="flex"
      flexDir="column"
      px={{ base: '0', md: '16' }}
    >
      <canvas ref={canvasRef} />
      <Text>{content}</Text>
    </Box>
  );
};

export const QuestionContent: React.FC<{ question: ItemQuestion }> = ({
  question,
}) => {
  if (!question.answers?.length) return null;
  switch (question.questionType) {
    case 'radio':
    case 'radio_combo':
      return <RadioButtonContent question={question} />;
    case 'checkbox':
    case 'checkbox_combo':
      return <CheckboxContent question={question} />;
    case 'text':
    case 'paragraph':
    case 'number':
    case 'decimal':
    case 'integer':
    case 'money':
    case 'car_plate':
      return <TextContent question={question} />;
    case 'picture':
    case 'gallery':
      return <ImageContent question={question} />;
    case 'time_picker':
      return <TimePickerContent question={question} />;
    case 'date':
    case 'datetime':
    case 'time':
      return <DateContent question={question} />;
    case 'geolocation':
      return <GeolocationContent question={question} />;
    case 'signature':
      return <SignatureContent question={question} />;
    case 'collections':
      return <CollectionItemContent question={question} />;
    case 'barcode':
      return <BarcodeContent question={question} />;
    case 'chronometer':
      return <ChronometerContent question={question} />;
    case 'collections_combo':
      return <CollectionComboContent question={question} />;
    case 'user':
      return <UserContent question={question} />;

    case CIRCULABI_SIGNOFF_QUESTION_TYPE as any:
      return <ViewCirculabiSignoffResult question={question} />;
    default:
      return null;
  }
};
