/* eslint-disable react/no-array-index-key */
/* eslint-disable dot-notation */

import { DataTable } from '@/components_v2/DataTable';
import ExporterContextMenu from '@/components_v2/ExporterContextMenu';
import { FilterItem, FilterList } from '@/components_v2/Filtersv3/filter-item';
import {
  FilterScreenProvider,
  useFiltersByScreen,
} from '@/components_v2/Filtersv3/filter-store';
import { FilterCalendar } from '@/components_v2/Filtersv3/reactive-filter-calendar';
import SearchBar from '@/components_v2/inputs/SearchBar';
import { MacroColumnFilter, MacroTableFilter } from '@/gql/types';
import { useUserPermissions } from '@/hooks/Authentication/userPermissions';
import { useFetchOptions } from '@/hooks/useFetchOptions';
import { TRASH_ICON } from '@/screens/Tickets/utils/customIcons';
import { filterObject, reduceNestedFilters } from '@/utils/filters';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import { FiDownload } from 'react-icons/fi';

import { useTaskTabsNavigation } from '../Dashboard/hooks/useTaskTabsNavigation';
import { getExporter } from '../services/getExporter';
import {
  PublicTasksContext,
  usePublicTasks,
  usePublicTasksContext,
} from './hooks/usePublicTasks';
import { getTableColumns } from './hooks/utils';

function TableContent() {
  const {
    items,
    loading,
    paginate,
    pageInfo,
    rowSelection,
    setRowSelection,
  } = usePublicTasksContext();

  const renderSkeleton = React.useCallback(() => {
    const skeletonItems = new Array(9).fill(null);

    return skeletonItems.map((e, idx) => (
      <Tr h="70px" key={idx}>
        <Td>
          <Flex justifyContent="flex-start" gap="2.5" flexDir="row">
            <SkeletonCircle size="10" />
            <Box flex="1">
              <SkeletonText noOfLines={3} spacing="3" />
            </Box>
          </Flex>
        </Td>
        <Td>
          <Skeleton h="6" w="36" />
        </Td>
        <Td>
          <SkeletonText noOfLines={2} spacing="2" />
        </Td>
        <Td>
          <SkeletonText noOfLines={2} spacing="2" />
        </Td>
        <Td>
          <SkeletonText noOfLines={2} spacing="2" />
        </Td>
        <Td>
          <SkeletonText noOfLines={2} spacing="2" />
        </Td>
        <Td>
          <Skeleton h="6" w="36" />
        </Td>
      </Tr>
    ));
  }, []);

  return (
    <Flex
      overflowY="auto"
      display="block"
      flex="1"
      rounded="md"
      borderWidth="thin"
    >
      <DataTable
        isLoading={loading}
        onLoadMore={() => paginate()}
        renderLoading={renderSkeleton}
        withSelect
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        tableProps={{
          variant: 'striped',
          size: 'lg',
          fontSize: 'lg',
          background: 'white',
          fontWeight: 'semibold',
        }}
        headerProps={{
          h: 14,
          bg: 'white',
        }}
        columns={getTableColumns() as any}
        data={items}
      />
      {!!pageInfo?.hasNextPage && (
        <Flex p="10" alignItems="center" justifyContent="center">
          <Button colorScheme="brand" size="lg" onClick={() => paginate()}>
            Carregar mais
          </Button>
        </Flex>
      )}
    </Flex>
  );
}

function Filters() {
  const { setSearch, setFilters } = usePublicTasksContext();
  const { byKey } = useFiltersByScreen();

  const reduced = reduceNestedFilters(byKey);

  const taskFilters = filterObject(reduced);

  const usersFilters = { member_id: taskFilters.member_id };
  delete taskFilters['member_id'];

  const fomularyFilters = { formulary_id: taskFilters.formulary_id };
  delete taskFilters['formulary_id'];

  const finishedAtFilter = taskFilters.finished_at
    ? JSON.parse(taskFilters.finished_at)
    : {};
  delete taskFilters['finished_at'];

  const filters = {
    tasks: {
      ...taskFilters,
      ...usersFilters,
      ...finishedAtFilter,
      ...fomularyFilters,
    },
  };

  React.useEffect(() => {
    setFilters(filters);
  }, [byKey]);

  const usersQuery = useFetchOptions({
    column: MacroColumnFilter.FullName,
    table: MacroTableFilter.Users,
    first: 30,
    filters: {
      tasks: {
        create_origin: ['public'],
      },
    },
  });

  const checklistQuery = useFetchOptions({
    column: MacroColumnFilter.Name,
    table: MacroTableFilter.Formularies,
    first: 30,
    filters: {
      tasks: {
        create_origin: ['public'],
      },
    },
  });

  const fetchMoreUserNameFilter = React.useCallback(() => {
    usersQuery.fetchMore({
      variables: {
        after: usersQuery.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          macroFilters: {
            ...fetchMoreResult.macroFilters,
            edges: [
              ...prev.macroFilters.edges,
              ...fetchMoreResult.macroFilters.edges,
            ],
          },
        };
      },
    });
  }, [usersQuery]);

  const fetchMoreChecklistFilter = React.useCallback(() => {
    checklistQuery.fetchMore({
      variables: {
        after: checklistQuery.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          macroFilters: {
            ...fetchMoreResult.macroFilters,
            edges: [
              ...prev.macroFilters.edges,
              ...fetchMoreResult.macroFilters.edges,
            ],
          },
        };
      },
    });
  }, [checklistQuery]);

  const onSearchUserFilter = React.useCallback((search: string) => {
    usersQuery.refetch({
      search,
    });
  }, []);

  const onSearchChecklistFilter = React.useCallback((search: string) => {
    checklistQuery.refetch({
      search,
    });
  }, []);

  return (
    <HStack w="full" spacing={4}>
      <Text>Buscar Tarefas</Text>
      <SearchBar onDebouce debounceTime={1000} onSubmit={setSearch} />
      <FilterList>
        <FilterItem
          label="Usuário"
          filterKey="member_id"
          loading={usersQuery.loading}
          options={usersQuery.options}
          onSearch={onSearchUserFilter}
          hasMore={usersQuery.pageInfo?.hasNextPage}
          fetchMore={fetchMoreUserNameFilter}
        />
        <FilterItem
          label="Checklist"
          filterKey="formulary_id"
          loading={checklistQuery.loading}
          options={checklistQuery.options}
          onSearch={onSearchChecklistFilter}
          hasMore={checklistQuery.pageInfo?.hasNextPage}
          fetchMore={fetchMoreChecklistFilter}
        />
        <FilterCalendar label="Finalizado em" filterKey="finished_at" />
      </FilterList>
    </HStack>
  );
}

function Header() {
  const { rowSelection, onDeleteTasks } = usePublicTasksContext();
  const { checkPermission } = useUserPermissions();
  const { setTab } = useTaskTabsNavigation();

  return (
    <Flex
      ps="4"
      rounded="md"
      bg="gray.200"
      pe="1.5"
      py="1.5"
      overflowX="auto"
      gap="4"
      flexDir="row"
      justifyContent="space-between"
      style={{ zIndex: 2 }}
    >
      <Filters />
      <HStack>
        {checkPermission('admin') && (
          <Button
            size="md"
            onClick={() => {
              setTab('templates');
            }}
            colorScheme="brand"
          >
            Compartilhar template
          </Button>
        )}

        <ExporterContextMenu
          getExporter={getExporter}
          options={{ only_public_events: true }}
          button={
            <Button
              size="md"
              variant="solid"
              rightIcon={<Icon as={FiDownload} />}
            >
              Exportar Tarefas
            </Button>
          }
          screenName="tasks"
        />

        <ExporterContextMenu
          getExporter={getExporter}
          options={{ only_public_events: true }}
          button={
            <Button
              size="md"
              variant="solid"
              rightIcon={<Icon as={FiDownload} />}
            >
              Exportar respostas
            </Button>
          }
          screenName="task_answers"
        />

        {!!(rowSelection && Object.values(rowSelection).length) && (
          <Button
            disabled={!checkPermission('admin')}
            onClick={() => onDeleteTasks()}
            rightIcon={<TRASH_ICON />}
            size="md"
            colorScheme="red"
          >
            Excluir tarefas
          </Button>
        )}
      </HStack>
    </Flex>
  );
}

export function Content() {
  return (
    <Flex overflow="0" w="full" flexDir="column" gap="4">
      <Header />

      <TableContent />
    </Flex>
  );
}

export function PublicTasks() {
  const hook = usePublicTasks();
  return (
    <FilterScreenProvider screen="publicTasks">
      <PublicTasksContext value={hook}>
        <Content />
      </PublicTasksContext>
    </FilterScreenProvider>
  );
}
