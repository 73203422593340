/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */

import _ from 'lodash';

import { Badge } from '../components/badge';
import { QuestionContext, useReport, useSection } from './hooks/use-context';
import type { OptionData, QuestionData, QuestionType } from './types';

const questionTypeTranslations: Record<QuestionType, string> = {
  checkbox: 'Caixa de seleção',
  integer: 'Número inteiro',
  decimal: 'Número decimal',
  hour: 'Hora',
  money: 'Valor monetário',
  radio: 'Escolha única',
  radio_combo: 'Escolha única combo',
  checkbox_combo: 'Caixa de seleção combo',
  picture: 'Imagem',
  gallery: 'Galeria',
  collections: 'Coleções',
  collections_combo: 'Coleções combo',
  geolocation: 'Geolocalização',
  date: 'Data',
  signature: 'Assinatura',
  action_plan: 'Plano de ação',
  text: 'Texto',
  barcode: 'Código de barras',
  chronometer: 'Cronômetro',
  car_plate: 'Placa de carro',
  time_picker: 'Seletor de hora',
  paragraph: 'Parágrafo',
  task_button: 'Botão de tarefa',
  code_generator: 'Gerador de código',
};

function QuestionHeader({
  question,
  workspace,
}: {
  question: QuestionData;
  workspace?: string;
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
      <div
        style={{
          float: 'right',
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '1rem',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', gap: '4rem' }}>
          <span>
            <h2 style={{ fontSize: '0.875rem', fontWeight: 600 }}>
              {question.name}
            </h2>
          </span>
          {!!question.image && (
            <img
              src={question.image}
              alt=""
              style={{ height: '5rem', width: '5rem' }}
            />
          )}
        </div>
        {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
          {workspace && workspace.includes('sesrj') ? (
            <>
              <p>{questionTypeTranslations[question.questionType]}</p>
              {' / '}
              <p>{question.enabled ? 'Ativo' : 'Inativo'}</p>
              {' / '}
              {question.required && <p>Obrigatória</p>}
            </>
          ) : (
            <>
              <Badge colorScheme="blue">
                {questionTypeTranslations[question.questionType]}
              </Badge>
              <Badge colorScheme={question.enabled ? 'green' : 'gray'}>
                {question.enabled ? 'Ativo' : 'Inativo'}
              </Badge>
              {question.required && (
                <Badge colorScheme="red">Obrigatória</Badge>
              )}
            </>
          )}
        </div> */}
      </div>
    </div>
  );
}

export function QuestionItem({
  children,
  question,
  isChild,
  workspace,
}: {
  children?: React.ReactNode;
  isChild?: boolean;
  question: QuestionData;
  workspace?: string;
}) {
  return (
    <li
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingTop: isChild ? '16px' : '32px',
        paddingBottom: isChild ? '16px' : '32px',
        borderTop: isChild ? '1px solid' : 'none',
        borderBottom: '1px solid',
        pageBreakInside: 'avoid',
      }}
    >
      <QuestionHeader question={question} workspace={workspace} />
      {!!children && (
        <div
          style={{
            wordBreak: 'break-word',
            backgroundColor: '#f3f4f6',
            padding: '12px',
          }}
        >
          {children}
        </div>
      )}
    </li>
  );
}

function CheckboxContent({ options }: { options: OptionData[] }) {
  const renderItem = (item: OptionData) => {
    return (
      <li style={{ width: '100%' }}>
        <div
          style={{
            width: '100%',
            overflow: 'hidden',
            borderRadius: '0.5rem',
            borderBottom: '1px solid #e5e7eb',
            backgroundColor: '#ffffff',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '12px',
            }}
            key={item.name}
          >
            <input
              type="checkbox"
              style={{
                pointerEvents: 'none',
                height: '20px',
                width: '20px',
                borderRadius: '0.25rem',
                borderColor: '#d1d5db',
                outline: 'none',
              }}
            />
            <label
              style={{
                marginLeft: '8px',
                width: '100%',
                paddingTop: '12px',
                paddingBottom: '12px',
                fontSize: '0.875rem',
                fontWeight: 500,
                color: '#111827',
              }}
            >
              {item.name}
            </label>
          </div>
        </div>
        {item?.childQuestions && item?.childQuestions.length > 0 && (
          <div style={{ padding: '16px', width: '100%' }}>
            {item?.childQuestions?.map((child) => (
              <QuestionItem key={child.name} question={child} isChild>
                {child.options && child.options.length && (
                  <CheckboxContent options={child.options} />
                )}
              </QuestionItem>
            ))}
          </div>
        )}
      </li>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <ul style={{ width: '100%', listStyleType: 'none' }}>
        {options?.map((option: OptionData) => renderItem(option))}
      </ul>
    </div>
  );
}

export function QuestionList() {
  const { questions } = useSection();
  const { workspace } = useReport();

  return (
    <ul style={{ width: '100%', padding: '4px' }}>
      {_.orderBy(questions, 'position', 'asc').map((question, idx) => (
        <QuestionContext value={question} key={`${question.name}-${idx}`}>
          <QuestionItem question={question} workspace={workspace}>
            {question.options && question.options.length > 0 && (
              <CheckboxContent options={question.options} />
            )}
          </QuestionItem>
        </QuestionContext>
      ))}
    </ul>
  );
}
