import _ from 'lodash';
import { useMemo } from 'react';

import { useReport } from './hooks/use-context';
import type { AnswerData } from './types';

export function ImagesGrid() {
  const { body } = useReport();

  const reducedImages = useMemo(() => {
    return body?.reduce((prev, curr) => {
      const nextState = _.orderBy(
        curr.sections.map((section) => section.questions).flat(),
        'position',
        'desc',
      )
        .filter((e) => ['gallery', 'picture'].includes(e.type))
        .map((q) => q.answers)
        .flat();
      const arr = [...prev, ...nextState];
      return arr;
    }, [] as Array<AnswerData>);
  }, [body]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: '1rem',
        borderTop: '1px solid',
        paddingTop: '1rem',
      }}
    >
      <p
        style={{
          fontSize: '1.5rem',
          fontWeight: 600,
        }}
      >
        Imagens
      </p>
      <div
        style={{
          width: '100%',
          columnCount: 1,
        }}
      >
        {reducedImages?.map((answer) => (
          <div
            key={answer.id}
            style={{ height: '100%', display: 'flex', alignItems: 'center' }}
          >
            <img
              style={{
                breakInside: 'avoid',
                margin: '0.5rem',
                objectFit: 'contain',
                height: 'auto',
                maxHeight: '700px',
                width: '100%',
              }}
              src={`${answer.content || ''}`}
              alt="img"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
